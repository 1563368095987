<template>
  <v-container
    class="products-list-page"
    fluid>
    <member-selector-modal
      :dialog="memberSelectorModal"
      @input="receiveMembers($event)"
      @close-modal="memberSelectorModalClose()" />
    <v-form ref="promotion-form">
      <v-row class="products-row">
        <v-col
          cols="6"
          offset="3">
          <v-row>
            <v-col cols="12">
              <h4>Coupon</h4>
            </v-col>
            <v-col
              v-if="!editMode"
              cols="12"
              class="pb-0">
              <v-checkbox
                :value="createTypeCompute('randomPerTel')"
                label="Random code per tel"
                hide-details
                @click="createTypeSetter('randomPerTel')" />
            </v-col>
            <v-col
              v-if="!editMode"
              cols="6"
              class="pr-0">
              <v-checkbox
                :value="createTypeCompute('randomPerCount')"
                label="Random code per count"
                hide-details
                @click="createTypeSetter('randomPerCount')" />
            </v-col>
            <v-col
              v-if="!editMode"
              cols="4"
              class="pl-0 pb-0 pt-6">
              <v-text-field
                v-model.number="randomCount"
                dense
                outlined
                :rules="!createTypeCompute('randomPerCount') ? [] : rules.randomCount"
                :disabled="!createTypeCompute('randomPerCount')" />
            </v-col>
            <v-col
              cols="7"
              class="pr-1">
              <v-text-field
                v-model="form.code"
                :rules="(createType !== 'singleCode') ? [] : rules.code"
                :disabled="createType !== 'singleCode'"
                label="CODE"
                dense
                outlined />
            </v-col>
            <v-col
              cols="5"
              class="pl-0">
              <v-btn
                block
                outlined
                height="40px"
                :disabled="createType !== 'singleCode'"
                @click="getRandomCode()">
                Random Code
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="form.type"
                :items="options.types"
                :rules="rules.type"
                label="ประเภทส่วนลด"
                dense
                outlined />
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="form.discountType"
                :items="options.discountTypes"
                :rules="rules.discountType"
                label="ชนิดของการลด"
                dense
                outlined
                :disabled="form.type === 'CELEB'" />
            </v-col>
            <v-col cols="7">
              <v-text-field
                v-model.number="form.discountNumber"
                :rules="rules.discountNumber"
                min="1"
                label="มูลค่าที่ลดราคา"
                dense
                outlined
                type="number"
                :disabled="form.type === 'CELEB'"
                @keypress="$keypressNumber($event)" />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.maxPrice"
                class="mt-1"
                hide-details
                dense
                :disabled="form.discountType === 'amount'" />
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model.number="form.maxPrice"
                :disabled="!switches.maxPrice"
                label="ลดสูงสุด"
                dense
                hide-details
                outlined
                type="number"
                @keypress="$keypressNumber($event)" />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.minPrice"
                class="mt-1"
                hide-details
                dense />
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model.number="form.minPrice"
                :disabled="!switches.minPrice"
                label="ยอดขั้นต่ำ"
                dense
                hide-details
                outlined
                type="number"
                @keypress="$keypressNumber($event)" />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.perUser"
                class="mt-1"
                hide-details
                dense />
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model.number="form.perUser"
                :disabled="!switches.perUser"
                label="จำนวนการใช้ต่อคน"
                dense
                hide-details
                outlined
                type="number"
                @keypress="$keypressNumber($event)" />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.amount"
                class="mt-1"
                hide-details
                dense />
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model.number="form.amount"
                :disabled="!switches.amount"
                label="จำนวนสิทธิ์"
                dense
                hide-details
                outlined
                type="number"
                @keypress="$keypressNumber($event)" />
            </v-col>
            <v-col cols="6">
              <date-input
                v-model="form.dateRange.startDate"
                label="ระยะเวลาเริ่ม" />
            </v-col>
            <v-col cols="6">
              <date-input
                v-model="form.dateRange.endDate"
                label="ระยะเวลาสิ้นสุด" />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.productCategoryIds"
                class="mt-1"
                hide-details
                dense />
            </v-col>
            <v-col cols="10">
              <v-combobox
                v-model="form.productCategoryIds.values"
                prepend-inner-icon="mdi-label"
                :items="options.categories"
                :disabled="!switches.productCategoryIds"
                append-icon
                outlined
                dense
                hide-selected
                label="หมวดหมู่สินค้า"
                multiple
                deletable-chips
                hide-details
                persistent-hint
                small-chips />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.productTagIds"
                class="mt-1"
                hide-details
                dense />
            </v-col>
            <v-col cols="10">
              <v-combobox
                v-model="form.productTagIds.values"
                :items="options.tags"
                prepend-inner-icon="mdi-label"
                :disabled="!switches.productTagIds"
                append-icon
                outlined
                dense
                deletable-chips
                hide-selected
                label="แท็กของสินค้า"
                multiple
                hide-details
                persistent-hint
                small-chips />
            </v-col>
            <v-col cols="12">
              <v-radio-group
                v-model="form.productTagIds.include"
                label="ประเภทการใช้แท็ก"
                row>
                <v-radio
                  label="ใช้ได้กับ Tag"
                  :value="true" />
                <v-radio
                  label="ใช้ไม่ได้กับ Tag"
                  :value="false" />
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.minAmount"
                class="mt-1"
                hide-details
                dense />
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model.number="form.minAmount"
                :disabled="!switches.minAmount"
                label="จำนวนสินค้าขั้นต่ำ"
                dense
                hide-details
                outlined
                type="number"
                @keypress="$keypressNumber($event)" />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="switches.forMemberTels"
                class="mt-1"
                hide-details
                dense />
            </v-col>
            <v-col cols="10">
              <v-combobox
                v-model="form.forMemberTels.values"
                prepend-inner-icon="mdi-label"
                append-icon="mdi-account-box-outline"
                :disabled="!switches.forMemberTels"
                outlined
                dense
                deletable-chips
                hide-selected
                label="เบอร์ลูกค้าที่ใช้ได้"
                multiple
                hide-details
                persistent-hint
                small-chips
                @change="handleEnterTel"
                @click:append="memberSelectorModalOpen()" />
            </v-col>
            <v-col cols="12">
              <v-radio-group
                v-model="form.campaignType"
                label="ประเภทของ Promotion Code">
                <v-radio
                  v-for="campaign of options.campaignTypes"
                  :key="`campaign-${campaign.value}`"
                  :label="campaign.text"
                  :value="campaign.value" />
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              class="d-flex">
              <v-checkbox
                v-model="channel"
                label="ใช้ได้กับออนไลน์"
                value="web"
                :rules="checkboxRules" />
              <v-checkbox
                v-model="channel"
                class="ml-2"
                label="ใช้ได้กับหน้าร้าน"
                value="pos"
                :rules="checkboxRules" />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.note"
                outlined
                label="บันทึก"
                dense
                hide-details />
            </v-col>
            <v-col cols="12">
              <v-btn
                v-if="!editMode"
                block
                color="primary"
                @click="submitForm()">
                สร้าง
              </v-btn>
              <v-btn
                v-else
                block
                color="primary"
                @click="update()">
                แก้ไข
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionProvider from '@/resources/PromotionProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import MemberProvider from '@/resources/MemberProvider'
import DateInput from '../components/DateInput.vue'
import MemberSelectorModal from '../components/MemberSelectorModal.vue'

const PromotionService = new PromotionProvider()
const ProductAttributeService = new ProductAttributeProvider()
const MemberService = new MemberProvider()

export default {
  components: {
    DateInput,
    MemberSelectorModal
  },
  data () {
    return {
      memberSelectorModal: false,
      form: {
        code: null,
        dateRange: {
          startDate: null,
          endDate: null
        },
        discountType: null,
        discountNumber: null,
        minPrice: null,
        maxPrice: null,
        perUser: null,
        amount: null,
        productCategoryIds: {
          status: false,
          values: []
        },
        productTagIds: {
          status: false,
          values: [],
          include: true
        },
        minAmount: null,
        forMemberTels: {
          status: false,
          values: []
        },
        channel: null,
        campaignType: 'brand_campaign',
        note: null
      },
      productCategories: [],
      channel: [],
      options: {
        campaignTypes: [
          { text: 'Brand', value: 'brand_campaign' },
          { text: 'Member', value: 'member_campaign' },
          { text: 'First Online Purchase', value: 'first_online_purchase' }
        ],
        discountTypes: [
          { text: 'บาท', value: 'amount' },
          { text: '%', value: 'percentage' }
        ],
        types: [
          { text: 'Member (VIP / VVIP)', value: 'MEMBER' },
          { text: 'Store Credit', value: 'STORE_CREDIT' },
          { text: 'Gift voucher', value: 'GIFT_VOUCHER' },
          { text: 'Giveaway (ของแถม)', value: 'GIVEAWAY' },
          { text: 'Celeb (ส่วนลดของ Celeb)', value: 'CELEB' },
          { text: 'Benefits (ส่วนลดของพนักงาน)', value: 'EMPLOYEE_BENEFITS' }
        ],
        tags: [],
        categories: []
      },
      createType: 'singleCode',
      randomCount: 0,
      memberSelected: [],
      switches: {
        minPrice: false,
        maxPrice: false,
        perUser: false,
        amount: false,
        productCategoryIds: false,
        productTagIds: false,
        minAmount: false,
        forMemberTels: false
      },
      validateFields: {
        code: true,
        discountType: true,
        discountNumber: true,
        channel: true
      },
      rules: {
        randomCount: [
          (v) => v > 0 || 'กรุณากรอกจำนวนมากกว่า 0'
        ],
        code: [
          (v) => !!v || 'กรุณากรอกข้อมูลให้ถูกต้อง',
          (v) => /^[a-zA-Z0-9]+$/.test(v) || 'กรุณากรอกข้อมูลเฉพาะตัวอักษรและตัวเลข'
        ],
        discountType: [
          (v) => !!v || 'กรุณากรอกข้อมูลให้ถูกต้อง'
        ],
        type: [
          (v) => !!v || 'กรุณากรอกข้อมูลให้ถูกต้อง'
        ],
        discountNumber: [
          (v) => !!v || 'กรุณากรอกข้อมูลให้ถูกต้อง'
        ],
        channel: [
          (v) => !!v || 'กรุณากรอกข้อมูลให้ถูกต้อง'
        ]
      }
    }
  },
  computed: {
    editMode () {
      return this.$route.name === 'EditPromotion'
    },
    checkboxRules () {
      return [this.channel.length > 0 || 'กรุณาเลือกช่องทาง']
    }
  },
  watch: {
    channel (value) {
      this.form.channel = value.length > 1 ? 'all' : value[0]
    },
    'form': {
      handler () {
        this.validateForm()
      },
      deep: true
    },
    'switches.forMemberTels': {
      handler (value) {
        if (!value && this.createType === 'randomPerTel') {
          this.createType = 'singleCode'
        }
      },
      deep: true
    },
    'form.type': {
      handler (val) {
        if (val === 'CELEB') {
          this.form.discountType = 'percentage'
          this.form.discountNumber = 100
        }
      },
      deep: true
    },
    'form.discountType': {
      handler (val) {
        if (val === 'amount') {
          this.switches.maxPrice = false
        }
      },
      deep: true
    }
  },
  async created () {
    await this.getAttribute()
    if (this.editMode) {
      await this.getPromotion()
      this.enableSwitch()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    handleEnterTel () {
      const tels = []
      this.form.forMemberTels.values.forEach((v) => { tels.push(...v.split(' ')) })
      this.form.forMemberTels.values = tels.map((tel) => {
        let temp = tel
        if (tel[0] === '0') {
          temp = `+66${tel.slice(1)}`
        }

        return temp
      })
    },
    async getPromotion () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GET PROMOTION...'
        })

        const { data } = await PromotionService.getPromotion(this.$route.params.id)
        const prepared = await this.initPromotionForEdit(data)
        this.form = prepared
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET PROMOTION ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async initForMemberTels (promotion) {
      const members = await this.getMembersById(promotion.forMemberIds.values)
      this.memberSelected = members.filter((member) => !!member)
      const forMemberTels = {
        status: promotion.forMemberIds.status,
        values: members.filter((member) => !!member).map((member) => this.telFormatter(member.tel, member?.telInfo?.dialCode || '66'))
      }

      return forMemberTels
    },
    async getMembersById (ids) {
      let members = []
      try {
        members = await Promise.all(ids.map((id) => MemberService.getMemberById(id)))
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[RANDOM CODE ERROR]: ${error.message}`,
          type: 'error'
        })
      }

      return members.map((member) => member.data)
    },
    initProductCategoryIds (promotion) {
      const productCategoryIds = {
        status: promotion.productCategoryIds.status,
        values: promotion.productCategoryIds.values.map((each) => this.options.categories.find((category) => category.value === each))
      }

      return productCategoryIds
    },
    initProductTagIds (promotion) {
      const productTagIds = {
        status: promotion.productTagIds.status,
        include: promotion.productTagIds.include,
        values: promotion.productTagIds.values.map((each) => this.options.tags.find((tag) => tag.value === each))
      }

      return productTagIds
    },
    async initPromotionForEdit (_promotion) {
      const productCategoryIds = this.initProductCategoryIds(_promotion)
      const productTagIds = this.initProductTagIds(_promotion)
      const forMemberTels = await this.initForMemberTels(_promotion)
      const promotion = {
        code: _promotion.code,
        discountType: _promotion.discountType,
        discountNumber: _promotion.discountNumber,
        type: _promotion.type,
        dateRange: _promotion.dateRange,
        minPrice: _promotion.minPrice === -1 ? null : _promotion.minPrice,
        maxPrice: _promotion.maxPrice === -1 ? null : _promotion.maxPrice,
        perUser: _promotion.perUser === -1 ? null : _promotion.perUser,
        amount: _promotion.amount === -1 ? null : _promotion.amount,
        productCategoryIds,
        productTagIds,
        minAmount: _promotion.minAmount === -1 ? null : _promotion.minAmount,
        forMemberTels,
        forMemberIds: _promotion.forMemberIds,
        channel: _promotion.channel,
        campaignType: _promotion?.campaignType || 'brand_campaign',
        note: _promotion?.note || ''
      }

      this.channel = promotion.channel === 'all' ? ['web', 'pos'] : [promotion.channel]

      return promotion
    },
    async getRandomCode () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'RANDOMING CODE...'
        })

        const { data } = await PromotionService.getRandomCode()
        this.form.code = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[RANDOM CODE ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async submitForm () {
      const validate = await this.validateForm(true)

      if (!validate) {
        return
      }

      if (this.createType === 'singleCode') {
        const prepared = this.preparedPromotionForm(this.form, this.switches)
        await this.createPromotion(prepared, 'single')
      } else if (this.createType === 'randomPerTel') {
        if (this.memberSelected.length > 0) {
          const prepared = this.preparedForRandomPerTelCode(this.form, this.switches, this.memberSelected)
          await this.createPromotion(prepared, 'many')
        } else {
          this.setSnackbar({
            value: true,
            message: 'กรุณาเลือกสมาชิก',
            type: 'error'
          })
        }
      } else if (this.createType === 'randomPerCount') {
        const prepared = this.preparedForRandomPerCountCode(this.form, this.switches, this.memberSelected)
        await this.createPromotion(prepared, 'many')
      }
    },
    preparedForRandomPerTelCode (_promotionForm, _refers, _members) {
      const promotionForm = JSON.parse(JSON.stringify(_promotionForm))
      const refers = JSON.parse(JSON.stringify(_refers))
      const removeDisabledData = this.removeDisabledData(promotionForm, refers)
      const prepared = this.removeUnuseData(removeDisabledData)
      prepared.dateRange = this.dateRangePrepared(prepared.dateRange)
      delete promotionForm.forMemberTels
      promotionForm.code = ''
      prepared.productCategoryIds.values = typeof prepared.productCategoryIds.values === 'object'
        ? prepared.productCategoryIds.values.map((category) => category.value)
        : prepared.productCategoryIds.values
      prepared.productTagIds.values = typeof prepared.productTagIds.values === 'object'
        ? prepared.productTagIds.values.map((tag) => tag.value)
        : prepared.productTagIds.values

      const forms = {
        promotionCodes: _members.map((member) => ({
          ...promotionForm,
          forMemberIds: {
            status: true,
            values: [member.id]
          }
        }))
      }

      return forms
    },
    preparedForRandomPerCountCode (_promotionForm, _refers, _members) {
      const promotionForm = JSON.parse(JSON.stringify(_promotionForm))
      const refers = JSON.parse(JSON.stringify(_refers))
      const removeDisabledData = this.removeDisabledData(promotionForm, refers)
      const prepared = this.removeUnuseData(removeDisabledData)
      prepared.dateRange = this.dateRangePrepared(prepared.dateRange)
      delete promotionForm.forMemberTels
      promotionForm.code = ''
      prepared.productCategoryIds.values = typeof prepared.productCategoryIds.values === 'object'
        ? prepared.productCategoryIds.values.map((category) => category.value)
        : prepared.productCategoryIds.values
      prepared.productTagIds.values = typeof prepared.productTagIds.values === 'object'
        ? prepared.productTagIds.values.map((tag) => tag.value)
        : prepared.productTagIds.values

      const forms = {
        promotionCodes: Array(this.randomCount).fill(0).map(() => {
          const payload = {
            ...promotionForm,
            forMemberIds: {
              status: false,
              values: []
            }
          }

          if (this.switches.forMemberTels) {
            payload.forMemberIds = {
              status: this.switches.forMemberTels,
              values: _members.map((member) => member.id)
            }
          }

          return payload
        })
      }

      return forms
    },
    preparedPromotionForm (_promotionForm, _refers) {
      const promotionForm = JSON.parse(JSON.stringify(_promotionForm))
      const refers = JSON.parse(JSON.stringify(_refers))
      const removeDisabledData = this.removeDisabledData(promotionForm, refers)
      const prepared = this.removeUnuseData(removeDisabledData)
      prepared.productCategoryIds.values = typeof prepared.productCategoryIds.values === 'object'
        ? prepared.productCategoryIds.values.map((category) => category.value)
        : prepared.productCategoryIds.values
      prepared.productTagIds.values = typeof prepared.productTagIds.values === 'object'
        ? prepared.productTagIds.values.map((tag) => tag.value)
        : prepared.productTagIds.values

      prepared.productCategoryIds.status = this.switches.productCategoryIds
      prepared.productTagIds.status = this.switches.productTagIds
      prepared.forMemberTels.status = this.switches.forMemberTels

      prepared.dateRange = this.dateRangePrepared(prepared.dateRange)

      return prepared
    },
    memberSelectorModalOpen () {
      this.memberSelectorModal = true
    },
    memberSelectorModalClose () {
      this.memberSelectorModal = false
    },
    forMemberTelsTransform (members) {
      this.form.forMemberTels = {
        status: true,
        values: members.map((member) => this.telFormatter(member.tel, member?.telInfo?.dialCode || '66'))
      }
    },
    telFormatter (_tel, dialCode) {
      let tel = _tel
      if (tel[0] === '0' && dialCode === '66') {
        tel = `+66${tel.slice(1)}`
      }

      return tel
    },
    receiveMembers (members) {
      this.memberSelected = members
      this.forMemberTelsTransform(members)
      this.memberSelectorModalClose()
    },
    dateRangePrepared (_dateRange) {
      const dateRange = {
        startDate: _dateRange.startDate || null,
        endDate: _dateRange.endDate || null
      }

      return dateRange
    },
    async update () {
      const validate = await this.validateForm(true)
      if (validate) {
        const prepared = this.preparedPromotionForm(this.form, this.switches)
        await this.editPromotion(prepared)
      }
    },
    async editPromotion (promotionForm) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING PROMOTION...'
        })

        await PromotionService.updatePromotion(this.$route.params.id, promotionForm)

        this.setSnackbar({
          value: true,
          message: 'แก้ไขโปรโมชั่นสำเร็จ',
          type: 'success'
        })
        this.$router.push({ name: 'PromotionManagement' })
      } catch (error) {
        console.error('editPromotion', error)
        this.setSnackbar({
          value: true,
          message: `[UPDATE PROMOTION CODE ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async createPromotion (promotionForm, type) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'CREATING PROMOTION...'
        })

        if (type === 'single') {
          await PromotionService.createPromotion(promotionForm)
        } else if (type === 'many') {
          await PromotionService.createMultiPromotion(promotionForm)
        }

        this.setSnackbar({
          value: true,
          message: 'สร้างโปรโมชั่นสำเร็จ',
          type: 'success'
        })

        this.$router.push({ name: 'PromotionManagement' })
      } catch (error) {
        console.error('createPromotion', error)
        this.setSnackbar({
          value: true,
          message: `[CREATE PROMOTION CODE ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    isObject (object) {
      return object != null && typeof object === 'object'
    },
    removeUnuseData (_obj, nested = false) {
      const obj = _obj
      const keys = Object.keys(obj)
      keys.forEach((key) => {
        if (key !== 'options') {
          if (nested) {
            if (obj[key] && (this.isObject(obj[key]) && JSON.stringify(obj[key]) !== '{}')) {
              obj[key] = this.removeUnuseData(obj[key])
            } else if (!obj[key] || JSON.stringify(obj[key]) === '{}') {
              delete obj[key]
            }
          } else if (!obj[key] || JSON.stringify(obj[key]) === '{}') {
            delete obj[key]
          }
        }
      })

      return JSON.stringify(obj) !== '{}' ? obj : null
    },
    removeDisabledData (_form, refers) {
      const form = _form
      const normalFields = ['minPrice', 'maxPrice', 'perUser', 'amount', 'minAmount']
      const nestedFields = ['productCategoryIds', 'productTagIds', 'forMemberTels']
      const keys = Object.keys(form)

      keys.forEach((key) => {
        if (normalFields.some((each) => each === key) && !refers[key] && form[key]) {
          delete form[key]
        } else if (nestedFields.some((each) => each === key) && !refers[key] && form[key]) {
          form[key].status = false
        }
      })

      return form
    },
    async getAttribute () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING ATTRIBUTE...'
        })

        const { data } = await ProductAttributeService.getAllProductAttribute()
        this.options.categories = data.categories.map((category) => ({ text: category.name, value: category.id }))
        this.options.tags = data.tags.map((category) => ({ text: category.name, value: category.id }))
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET ATTRIBUTES ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async validateForm (alert = false) {
      const valid = await this.$refs['promotion-form'].validate()

      if (alert && !valid) {
        this.setSnackbar({
          value: true,
          message: 'กรุณากรอกฟิลด์ให้ถูกต้อง',
          type: 'error'
        })
      }

      return valid
    },
    createTypeSetter (value) {
      this.createType = value === this.createType ? 'singleCode' : value

      if (this.createType === 'randomPerTel') {
        this.switches.forMemberTels = true
      }
    },
    createTypeCompute (type) {
      return this.createType === type
    },
    enableSwitch () {
      const normalFields = ['minPrice', 'maxPrice', 'perUser', 'amount', 'minAmount']
      const nestedFields = ['productCategoryIds', 'productTagIds', 'forMemberTels']

      normalFields.forEach((key) => {
        if (this.form[key] || this.form[key] === 0) {
          this.switches[key] = true
        }
      })

      nestedFields.forEach((key) => {
        this.switches[key] = this.form[key].status
      })
    }
  }
}
</script>

<style scoped>
.error-text {
  color: red;
  font-size: 14px;
}
</style>
