<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    class="bg-white"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateDisplayFormat"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        outlined
        hide-details
        dense
        v-bind="attrs"
        v-on="on" />
    </template>
    <v-date-picker
      v-model="date"
      color="secondary"
      class="date-picker">
      <!-- <v-btn
        text
        color="primary"
        @click="clearValue()"
      >
        ล้างค่า
      </v-btn> -->
      <!-- <v-spacer />
      <v-btn
        text
        small
        color="secondary"
        @click="menu = false">
        ยกเลิก
      </v-btn>
      <v-btn
        text
        small
        color="secondary"
        @click="$refs.menu.save(date)">
        ยืนยัน
      </v-btn> -->
    </v-date-picker>
    <v-time-picker
      v-model="time"
      color="secondary"
      format="24hr"
      class="time-picker">
      <!-- <v-spacer />
      <v-btn
        text
        small
        color="secondary"
        @click="menu = false">
        ยกเลิก
      </v-btn>
      <v-btn
        text
        small
        color="secondary"
        @click="$refs.menu.save(date)">
        ยืนยัน
      </v-btn> -->
    </v-time-picker>
  </v-menu>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        menu: false
      }
    },
    computed: {
      formData: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      date: {
        get () {
          if (!this.formData) {
            return null
          }

          return this.$dayjs(this.formData).format('YYYY-MM-DD')
        },
        set (value) {
          this.formData = this.$dayjs(`${value} ${this.time || '00:00'}`).utc().format()
        }
      },
      time: {
        get () {
          if (!this.formData) {
            return null
          }

          return this.$dayjs(this.formData).format('HH:mm')
        },
        set (value) {
          this.formData = this.$dayjs(`${this.date || this.$dayjs().format('YYYY-MM-DD')} ${value}`).utc().format()
        }
      },
      dateDisplayFormat () {
        return this.value ? this.$dayjs(this.value).locale('th').format('DD MMM YYYY HH:mm') : 'ไม่กำหนด'
      }
    },
    methods: {
      clearValue () {
        this.date = null
      }
    }
  }
</script>

<style scoped>
.bg-white {
  background-color: white !important;
}
</style>

<style>
.date-picker .v-picker__title {
  border-top-right-radius: 0px !important;
}
.time-picker .v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
  height: 56px !important;
}
.time-picker .v-picker__title {
  border-top-left-radius: 0px !important;
}
</style>
